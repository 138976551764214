// eslint-disable @typescript-eslint/no-unused-vars
// @ts-ignore

import * as React from 'react';
import { IActionContext } from '@msdyn365-commerce/core';
import MenuConfig from '../../../common/menu-config.json';
import { GridSize, MenuConfigItem } from './models';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const UseConfigFromSiteBuilder = false;

export type StaticMenuProps = {
    isMobileView: boolean;
    jsonMenu?: string;
    className?: string;
    actionBtn?: React.ReactNode;
    context: IActionContext;
    viewport: GridSize;
};

const StaticMenu = ({ isMobileView, jsonMenu, ...rest }: StaticMenuProps) => {
    const menuConfig = React.useMemo(() => {
        const menuConfig: MenuConfigItem[] = UseConfigFromSiteBuilder && jsonMenu ? JSON.parse(jsonMenu) : MenuConfig;
        return menuConfig;
    }, [jsonMenu, MenuConfig]);

    return isMobileView ? <MobileMenu menuConfig={menuConfig} {...rest} /> : <DesktopMenu menuConfig={menuConfig} {...rest} />;
};

export default StaticMenu;
