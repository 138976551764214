// eslint-disable @typescript-eslint/no-unused-vars
// eslint-disable @typescript-eslint/no-unused-imports
// @ts-ignore

/* eslint-disable no-duplicate-imports */
import MsDyn365, { IImageData } from '@msdyn365-commerce/core';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';

import { IHeiNavigationMenuViewProps } from './hei-navigation-menu';
import StaticMenu from './components/StaticMenu';

interface INavigationState {
    parentMenu?: number;
    activeMenu?: number;
    categoryImage?: IImageData[] | null;
    mobileViewLabelText?: string;
    categoryImageAltText: string;
    updateCounter: number;
}

/**
 *
 * NavigationMenuView component.
 * @extends {React.PureComponent<IHeiNavigationMenuViewProps>}
 */
export class HeiNavigationMenuView extends React.PureComponent<IHeiNavigationMenuViewProps, INavigationState> {
    private readonly menuNode: React.RefObject<HTMLUListElement>;

    private readonly menuItemRef: React.RefObject<HTMLElement>;

    constructor(props: IHeiNavigationMenuViewProps) {
        super(props);
        this.menuNode = React.createRef();
        this.menuItemRef = React.createRef();
        this.state = { activeMenu: undefined, mobileViewLabelText: '', parentMenu: undefined, categoryImageAltText: '', updateCounter: 0 };
        this._closeSubmenu = this._closeSubmenu.bind(this);
    }

    public componentDidMount(): void {
        if (MsDyn365.isBrowser) {
            document.body.addEventListener('mousedown', this._handleClickOutside);
            document.body.addEventListener('focusout', this._handleFocusOutside);
        }
    }

    public componentDidUpdate(): void {
        if (this.menuItemRef.current?.children[0] && this.props.isMobileView) {
            (this.menuItemRef.current.children[0] as HTMLElement).focus();
        }
    }

    public componentWillUnmount(): void {
        if (MsDyn365.isBrowser) {
            document.body.removeEventListener('mousedown', this._handleClickOutside, false);
            document.body.removeEventListener('focusout', this._handleFocusOutside, false);
        }
    }

    public render(): JSX.Element | null {
        const { isMobileView, MenuList, MobileBackButton, MobileDescriptionContainer, MobileDescriptionLabel, Navigation } = this.props;

        return (
            <Module
                {...Navigation}
                className={classnames(Navigation.className, isMobileView && this.state.activeMenu !== undefined ? 'child' : 'parent')}
            >
                <Node {...MenuList} ref={this.menuNode} tabIndex='-1'>
                    {isMobileView && this.state.activeMenu !== undefined && (
                        <Node {...MobileDescriptionContainer}>
                            <Node {...MobileBackButton} onClick={this._handleGoBack()} />
                            <Node {...MobileDescriptionLabel}>{this.state.mobileViewLabelText}</Node>
                        </Node>
                    )}
                    <StaticMenu
                        key={`menu-${this.state.updateCounter}`}
                        isMobileView={isMobileView}
                        jsonMenu={this.props.config.jsonMenu}
                        actionBtn={this.props.actionBtn}
                        context={this.props.context.actionContext}
                        viewport={this.props.viewport}
                    />
                </Node>
            </Module>
        );
    }

    private readonly _handleGoBack = () => () => {
        this.setState({ activeMenu: this.state.parentMenu });
    };

    /**
     * Method to handle click outside of menu.
     * @param event -HTML event.
     */
    private readonly _handleClickOutside = (event: MouseEvent) => {
        if (this.menuNode.current && !this.menuNode.current.contains(event.target as Node)) {
            this.setState({
                updateCounter: this.state.updateCounter + 1,
                activeMenu: undefined,
                mobileViewLabelText: '',
                categoryImage: null
            });
        }
    };

    /**
     * Method to handle click outside of menu.
     * @param event -HTML event.
     */
    private readonly _handleFocusOutside = (event: FocusEvent) => {
        if (this.menuNode.current && !this.menuNode.current.contains(event.relatedTarget as Node)) {
            this._closeSubmenu();
        }
    };

    private _closeSubmenu(): void {
        if (!this.props.isMobileView) {
            this.setState({
                updateCounter: this.state.updateCounter + 1,
                activeMenu: undefined,
                categoryImage: null,
                mobileViewLabelText: ''
            });
        }
    }
}

export default HeiNavigationMenuView;
