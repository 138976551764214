import { IActionContext } from '@msdyn365-commerce/core';

export const makeAbsUrl = (context: IActionContext, url: string) => {
    if (url[0] === '/') {
        let origin = `https://${window.location.host}`;

        if (context.requestContext.sitePath && process.env.NODE_ENV !== 'development') {
            origin += context.requestContext.sitePath;
        }

        return `${origin}${url}`;
    }

    return url;
};
