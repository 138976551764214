// eslint-disable @typescript-eslint/no-unused-vars
// @ts-ignore

import * as React from 'react';
import classnames from 'classnames';
import { makeAbsUrl } from '../../../common/utilities/makeAbsUrl';
import { MenuProps } from './models';

const DesktopMenu = ({ menuConfig, className, context, viewport }: MenuProps) => {
    const [openMenuIndex, setOpenMenuIndex] = React.useState<number | null>(null);
    const activeMenu = React.useMemo(() => {
        const activeMenu = (openMenuIndex !== null && menuConfig[openMenuIndex]) || null;
        // process.env.NODE_ENV === 'development' && console.log('hei-navigation-menu', 'activeMenu', activeMenu);
        return activeMenu;
    }, [openMenuIndex, menuConfig]);

    const isXl = viewport === 'xl';

    return (
        <div className={classnames(`hei-navi-menu`, className)}>
            <ul className='hei-menu-bar'>
                {menuConfig.map((c, idx) => (
                    <li
                        key={idx}
                        className={classnames({
                            active: openMenuIndex === idx
                        })}
                        onClick={() => setOpenMenuIndex(currentIndex => (currentIndex === idx ? null : idx))}
                    >
                        {c.label}
                    </li>
                ))}
            </ul>

            {/* Selected/opened menu */}
            {activeMenu && (
                <div className='menu-panel'>
                    <div className='menu-container'>
                        <h2 className={classnames(activeMenu.className)}>
                            {activeMenu.url ? (
                                <a href={makeAbsUrl(context, activeMenu.url)} target={activeMenu.target}>
                                    {activeMenu.label}
                                </a>
                            ) : (
                                <>{activeMenu.label}</>
                            )}
                        </h2>
                        <div className='row'>
                            {Array(isXl ? 6 : 4)
                                .fill(0)
                                .map((_, i) => {
                                    const col = i + 1;
                                    return (
                                        // Menu column
                                        <div key={col} className={classnames(`menu-col-${col}`, { 'col-3': !isXl, 'col-2': isXl })}>
                                            {activeMenu.children &&
                                                activeMenu.children
                                                    .filter(c =>
                                                        isXl
                                                            ? (c.columnXL || 1) === col
                                                            : (c.column || 1) === col || (col === 4 && (c.column || 1) > 4)
                                                    )
                                                    .map((section, sectionIdx) => {
                                                        const children = section.children;
                                                        return (
                                                            <div key={sectionIdx}>
                                                                {section.label && (
                                                                    <h3 className={classnames(section.className)}>
                                                                        {section.url ? (
                                                                            <a
                                                                                href={makeAbsUrl(context, section.url)}
                                                                                target={section.target}
                                                                            >
                                                                                {section.label}
                                                                            </a>
                                                                        ) : (
                                                                            <>{section.label}</>
                                                                        )}
                                                                    </h3>
                                                                )}
                                                                {children && (
                                                                    <ul>
                                                                        {children.map((menuItem, menuItemIdx) => (
                                                                            <li
                                                                                key={menuItemIdx}
                                                                                className={classnames(menuItem.className)}
                                                                            >
                                                                                {menuItem.url ? (
                                                                                    <a
                                                                                        href={makeAbsUrl(context, menuItem.url)}
                                                                                        target={menuItem.target}
                                                                                    >
                                                                                        {menuItem.label}
                                                                                    </a>
                                                                                ) : (
                                                                                    <>{menuItem.label}</>
                                                                                )}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                )}
                                                                {section.promo && (
                                                                    <div className={classnames('promo', section.promo.className)}>
                                                                        <a href={makeAbsUrl(context, section.promo.url)}>
                                                                            <img src={section.promo.image} title={section.promo.label} />
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DesktopMenu;
